import React, {useState} from 'react';
import ModalLayout from "../index";
import {formatterUS} from "../../../../utils";
import MarketplaceButton from "../../MarketplaceButton";
import './style.scss'
import {useAppContext} from "../../../../contexts/appContext";


const UpgradeKeyModal = ({onClose, isOpen, selectedToken, amountUpgradeKey, onApproveUpgradeKey, tresrUpgradeCost}) => {
    const { balances } = useAppContext()
    const [burnSmrtr, setBurnSmrtr] = useState(false);
    const tokenLabel = burnSmrtr ? '$SMRTR' : '$TRESR'
    const amount = burnSmrtr ? amountUpgradeKey : tresrUpgradeCost
    const balance = burnSmrtr ? balances.balanceSmrtr : balances.balanceTresr
    return (
        <ModalLayout
            onClose={onClose}
            isOpen={isOpen}
            maxWidth={'458px'}
            maxHeight={'480px'}
            padding={'32px'}
            withCrossIcon
        >
            <p className='modal__title'>Upgrade Treasure Key</p>
            <p className='modal__text upgradeKeyModal__text'>Upgrading your Key earns more $TRESR.</p>
            {
                selectedToken.zone !== 6 && <div className={"flex justify-center"} style={{margin: 'auto'}}>

                <MarketplaceButton title={`Switch to burn ${burnSmrtr ? '$TRESR' : '$SMRTR'}`} onClick={() => setBurnSmrtr(prev => !prev)} />

                </div>
            }

            <div className='flex justify-between my-3'>
                <p className='modal__text'>Key #</p>
                <span className='modal__text--blue'>Treasure Key #{selectedToken?.tokenId}</span>
            </div>
            <div className='flex justify-between my-3'>
                <p className='modal__text'>Next Level</p>
                <span className='modal__text--blue'>Level {+selectedToken?.level + 1}</span>
            </div>
            <div className='flex justify-between my-3'>
                <p className='modal__text'>To Burn</p>
                <span className='modal__text--blue'>{formatterUS(amount, burnSmrtr ? 0 : 4)} {tokenLabel}</span>
            </div>
            <div className='flex justify-between my-3'>
                <p className='modal__text'>Your {tokenLabel} Balance</p>
                <span className='modal__text--blue'>{formatterUS(balance)} {tokenLabel}</span>
            </div>
                <div className='modal__text text-center my-6 flex-grow justify-center items-end flex'>This action cannot be undone</div>
                <div className='upgradeKeyModal__buttons'>
                    <MarketplaceButton title={'Cancel'} onClick={onClose}/>
                    <MarketplaceButton title={'Upgrade'} isBlue onClick={() => onApproveUpgradeKey(amount, burnSmrtr)}/>
                </div>

        </ModalLayout>
    );
};

export default UpgradeKeyModal;
