import React, {createContext, useState, useContext, useCallback, useEffect} from 'react';
import useHandleNFT from "../hooks/blockchain/useHandleNFT";
import PubSub from "pubsub-js";
import {PubSubEvents} from "../constant/events";
import useHandleContracts from "../hooks/blockchain/useHandleContracts";
import markerSDK from "@marker.io/browser";
import {
  useDisconnect,
  useWeb3ModalAccount,
} from '@web3modal/ethers/react'
import {AVALANCHE_NETWORK_PARAMS} from "../constant/blockchain";

const AppContext = createContext({
  balances: {
    balanceAvax: 0,
    balanceSmrtr: 0,
    balanceTresr: 0,
    balanceLpTRESRAVAX: 0,
    balanceLpSMRTRAVAX: 0,
    balanceVeTresr: 0,
    burnedSmrtr: 0,
    poolAllocation: 0,
    bonusPoolAllocation: 0,
    logout: () => {}
  },
  totalNFKeySupply: 0,
  refreshBalances: (wallet_id) => {},
  hasAcceptedTerms: false,
  acceptTerms: () => {}
});
export const useAppContext = () => useContext(AppContext);
const initialBalances = {
  balanceAvax: 0,
  balanceSmrtr: 0,
  balanceTresr: 0,
  balanceLpTRESRAVAX: 0,
  balanceLpSMRTRAVAX: 0,
  balanceVeTresr: 0,
  burnedTresr: 0,
  burnedSmrtr: 0,
  poolAllocation: 0,
  bonusPoolAllocation: 0,
  totalNFKeySupply: 0,
};
export const AppContextProvider = ({ children }) => {
  const { address, isConnected, chainId } = useWeb3ModalAccount();
  const { disconnect } = useDisconnect();
  const useNft = useHandleNFT();
  const [balances, setBalances] = useState(initialBalances);
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const [totalNFKeySupply, setTotalNFKeySupply] = useState(0);
  const [initialChainId, setInitialChainId] = useState(0);

  const {
    contractNFKeyWithSigner,
    contractNFKeyStakingWithSigner,
    contractSignatureStorageWithSinger,
    contractNFKey,
    ethersSigner,
    contractMasterRewards
  } = useHandleContracts();

  useEffect(() => {
    if (!isConnected) {
      return;
    }

    if (initialChainId !== 0 && initialChainId !== AVALANCHE_NETWORK_PARAMS.chainId && chainId === AVALANCHE_NETWORK_PARAMS.chainId) {
      alert('Page will reload with proper network. Sign in again afterwards to continue.')
      window.location.reload();
    } else {
      setInitialChainId(chainId);
    }
  }, [address, isConnected, initialChainId, chainId]);

  useEffect(() => {
    if (!isConnected || !contractSignatureStorageWithSinger) {
      return;
    }

    const getHasSigned = async () => {

      const signed = await contractSignatureStorageWithSinger.getHasSigned(address);
      setHasAcceptedTerms(signed);
    }
    getHasSigned();
  }, [address, isConnected, contractSignatureStorageWithSinger]);

  const acceptTerms = useCallback(async () => {
    if (!isConnected) {
      return;
    }

    const signed = await ethersSigner.signMessage(process.env.REACT_APP_SIGNATURE_DISCLAIMER);
    const tx = await contractSignatureStorageWithSinger.submitSignature(process.env.REACT_APP_SIGNATURE_HASH, signed);
    await tx.wait();
    setHasAcceptedTerms(true);
  }, [isConnected, ethersSigner, contractSignatureStorageWithSinger]);

  const logout = useCallback(() => {
    disconnect();
    PubSub.publish(PubSubEvents.LOGOUT);
  }, [disconnect]);


  useEffect(() => {
    const logoutToken = PubSub.subscribe(PubSubEvents.LOGOUT, () => {
      setBalances(initialBalances);
    });

    const marker = async () => {
      if (process.env.REACT_APP_MARKER_ID) {
        const widget = await markerSDK.loadWidget({
          project: process.env.REACT_APP_MARKER_ID,
        });
      }
    }
    marker();


    return () => {
      PubSub.unsubscribe(logoutToken);
    };
  }, [address]);

  const refreshBalances = useCallback(async () => {
    if (!isConnected || !contractNFKeyWithSigner) {
      return;
    }

    const gameState = await Promise.all([
      contractNFKeyWithSigner.smrtrBurned(),
      contractNFKeyStakingWithSigner.tresrBurned(),
      contractMasterRewards.dispersedRewards(),
      contractMasterRewards.totalRewards(),
      contractNFKeyStakingWithSigner.totalTresrClaimed(),
      contractNFKeyStakingWithSigner.emissionsLimit(),

    ]).then((res) => {


      const dispersed = res[2].reduce((toRet, d) => toRet + Number(d.amount)/1e18, 0)
      const total = res[3].reduce((toRet, d) => toRet + Number(d.amount)/1e18, 0)


      return {
        burnedSmrtr: Number(res[0]) / 1e18,
        burnedTresr: Number(res[1]) /1e18,
        bonusPoolAllocation: total - dispersed,
        poolAllocation: (Number(res[5]) / 1e18) - (Number(res[4]) /1e18),
      }
    });

    const newBalances = await useNft.updateProfileBalance(address);
    setBalances({
      balanceAvax: Number(newBalances.avaxBalance.toFixed(6)),
      balanceLpSMRTRAVAX: Number(newBalances.lpSmrtrAvaxBalance.toFixed(6)),
      balanceSmrtr: Number(newBalances.smrtrBalance.toFixed(6)),
      balanceLpTRESRAVAX: Number(newBalances.lpTresrAvaxBalance.toFixed(6)),
      balanceTresr: Number(newBalances.tresrBalance.toFixed(6)),
      balanceVeTresr: Number(newBalances.veTresrBalance.toFixed(6)),
      burnedTresr: gameState.burnedTresr,
      burnedSmrtr: gameState.burnedSmrtr,
      poolAllocation: gameState.poolAllocation,
      bonusPoolAllocation: gameState.bonusPoolAllocation,
    })
  }, [
    address,
    isConnected,
    contractNFKeyWithSigner,
    contractNFKeyStakingWithSigner,
    contractMasterRewards,
    useNft,
  ]);

  useEffect(() => {
    if (!isConnected || !contractNFKeyWithSigner) {
      return
    }

    const claimToken = PubSub.subscribe(PubSubEvents.BALANCE_CHANGED, () => {
      refreshBalances();
    });

    refreshBalances();
    return () => {
      PubSub.unsubscribe(claimToken);
    };
  }, [isConnected, address, contractNFKeyWithSigner]);

  useEffect(() => {
    if (!contractNFKey) {
      return;
    }
    const getSupply = async () => {
      const supply = await contractNFKey
        .totalSupply()
        .then((res) => Number(res));
      setTotalNFKeySupply(supply)
    }

    getSupply();
  }, [contractNFKey]);


  const value = {
    balances,
    refreshBalances,
    hasAcceptedTerms,
    acceptTerms,
    logout,
    totalNFKeySupply
  };

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};
