import React, {useCallback, useContext, useEffect, useState} from "react";
import "./style.scss";
import ModalLayout from "../index";
import KeyItem from "./KeyItem";
import MarketplaceButton from "../../MarketplaceButton";
import { compareUnixDates, sleep } from "../../../../utils";
import { formatterUS } from "../../../../utils";
import useWindowDimensions from "hooks/useWidowDimensions";
import {useAppContext} from "../../../../contexts/appContext";

const UpgradeAllKeysModal = ({
  isOpen,
  onClose,
  filterTokenList,
  onClick,
  setTokenListToUpgrade,
  handleTransactionLoadingModal,
  boostKeys
}) => {
  const { balances } = useAppContext();
  const [tokenList, setTokenList] = useState([]);
  const [curDelToken, setCurDelToken] = useState(-1);
  const [totalAmount, setTotalAmount] = useState(0);
  const windowParams = useWindowDimensions();
  const isMobile = windowParams?.width <= 750;
  const [burnSmarter, setBurnSmarter] = useState(false)


  const isInsufficient = burnSmarter ? +totalAmount > +balances.balanceSmrtr : +totalAmount > balances.balanceTresr;
  const buttonTitle = isInsufficient
    ? "Insufficient balance"
    : `Upgrade ${
        tokenList?.filter((item) => item?.flag === true)?.length || 0
      } Keys`;

  const updateTotalAmount = useCallback(() => {
    const temp = tokenList
      ?.filter((item) => item?.flag === true)
      ?.reduce((toRet, item) => toRet + (burnSmarter ? item.keyUpgradeAmount : item.tresrUpgradeCost), 0);

    setTotalAmount(temp);
  }, [tokenList])

  const onDeleteItem = useCallback(async (id) => {
    setCurDelToken(id);
    setTokenList(
      tokenList?.map((item, key) => {
        if (item.tokenId === id) item.flag = false;
        return item;
      })
    );
  }, [tokenList, burnSmarter]);

  const onAddItem = useCallback(async (id) => {
    setTokenList(
      tokenList?.map((item, key) => {
        if (item.tokenId === id) item.flag = true;
        return item;
      })
    );
  }, [tokenList]);

  const onUpgrade = useCallback(() => {
    if (tokenList?.length) {
      setTokenListToUpgrade(
        tokenList
          ?.filter((item) => item?.flag === true && item.staked)
          ?.map((item) => ({
            id: item.tokenId,
            upgradeLevel: item.upgradeLevel,
            currentLevel: item.level,
            ready: item.ready
          }))
      );
      onClick(totalAmount, burnSmarter);
    }
  }, [onClick, tokenList, totalAmount, burnSmarter]);

  useEffect(() => {
    if (isOpen && filterTokenList?.length) {
      const tokenList = filterTokenList
        ?.filter((item) => item.staked && item.level < 150)
        .filter(item => {
          const ready = compareUnixDates(new Date().getTime() / 1000, item.keyUpgradeDelay);
          if (boostKeys) {
            return true
          }
          return ready
        })
        .filter((item) => burnSmarter ? item.zone !== 6 : true)
        ?.map((tokenItem) => {
          const ready = compareUnixDates(new Date().getTime() / 1000, tokenItem.keyUpgradeDelay);
          return { ...tokenItem, flag: true, ready };
        })
      setTokenList(tokenList);
    }
  }, [filterTokenList, isOpen, burnSmarter]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
      <ModalLayout
          isOpen={isOpen}
          onClose={onClose}
          maxWidth={"608px"}
          maxHeight={"620px"}
          padding={"32px"}
          withCrossIcon
      >
        <p className="modal__title">Upgrade All Keys</p>

        {!isMobile && (
            <div className="modal__text mt-4 text-center">
              Upgrading your Key Level earns more $TRESR.
            </div>
        )}

        <div className={"flex justify-center mt-4"}>
          <MarketplaceButton title={`Switch to burn ${burnSmarter ? '$TRESR' : '$SMRTR'}`}
                             onClick={() => setBurnSmarter(prev => !prev)}/>
        </div>
          {!isMobile && (
              <div className="modal__text text-right text-[16px] mr-[20px]">
                {tokenList?.filter((item) => item?.flag == true)?.length} /{" "}
                {tokenList?.length}
              </div>
          )}
          <div className={`upgradeAllKeysModal__list`}>
            {tokenList?.map((item, key) => {
              if (item.flag) {
                return (
                    <KeyItem
                        key={item.tokenId}
                        token={item}
                        onDeleteItem={onDeleteItem}
                        onAddItem={onAddItem}
                        index={item?.tokenId}
                        delI={curDelToken}
                        updateTotalAmount={updateTotalAmount}
                        burnSmarter={burnSmarter}
                        boostKeys={boostKeys}
                    />
                );
              }
            })}
            {tokenList?.map((item, key) => {
              if (!item.flag)
                return (
                    <KeyItem
                        key={item.tokenId}
                        token={item}
                        onDeleteItem={onDeleteItem}
                        onAddItem={onAddItem}
                        index={key}
                        updateTotalAmount={updateTotalAmount}
                        burnSmarter={burnSmarter}
                        boostKeys={boostKeys}
                    />
                );
            })}
          </div>

          <div className="upgradeAllKeysModal__total">
        <span className="upgradeAllKeysModal__total--text">
          You need to Burn
        </span>
            <div className="upgradeAllKeysModal__total--price">
              {formatterUS(totalAmount)} {burnSmarter ? 'SMRTR' : 'TRESR'}
            </div>
          </div>

          <div className="upgradeAllKeysModal__buttons flex-grow justify-end items-end">
            <MarketplaceButton title="Cancel" onClick={onClose}/>
            <MarketplaceButton
                disabled={isInsufficient}
                title={buttonTitle}
                onClick={onUpgrade}
                isBlue
            />
          </div>
      </ModalLayout>
);
};

export default UpgradeAllKeysModal;
